@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-zinc-100;
}

table tr {
  @apply border-y;
}
table td, table th {
  @apply p-2 text-left align-top;
}
